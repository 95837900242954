/* fonts and general styling */

@font-face {
  font-family:'InriaSans-Bold';
  font-display: swap;
  src: url('./fonts/InriaSans-Bold.ttf') format('woff2');
}
@font-face {
  font-family:'InriaSans-Regular';
  font-display: swap;
  src: url('./fonts/InriaSans-Regular.ttf') format('woff2');
}
@font-face {
  font-family:'Poppins-Regular';
  font-display: swap;
  src: url('./fonts/Poppins-Regular.ttf') format('woff2');
}
@font-face {
  font-family:'Poppins-Bold';
  font-display: swap;
  src: url('./fonts/Poppins-Bold.ttf') format('woff2');
}
@font-face {
  font-family:'Poppins-Medium';
  font-display: swap;
  src: url('./fonts/Poppins-Medium.ttf') format('woff2');
}
@font-face {
  font-family:'Poppins-SemiBold';
  font-display: swap;
  src: url('./fonts/Poppins-SemiBold.ttf') format('woff2');
}


::before,
::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role='list'],
ol[role='list'] {
  list-style-type: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg {
  max-width: 100%;
  display: block;
}

ul {
  list-style-type: none;
}

ul li a {
  text-decoration: none;
  color: black;
}


/* Header Navigation */


.logo-container, .logo-container img{
  height: 50px;
  width: 155px;
}


nav {
  padding: 5px 20px;
}

ul {
  list-style-type: none;
}

a {
  color: white;
  text-decoration: none;
}

.menu-main li {
font-size: 15px;
padding: 10px 0px 0px 0px;
}

.menu-main li a {
  display: block;
  font-family: 'Poppins-Regular';
  transition: .2s;
}

.menu-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}


.bars {
position: relative;
cursor: pointer;
order: 1;
}

.menu {
  height: 100%;
  width: 100%;
}

.item {
display: none;
transition: .2s;
}

.item-active {
  width: 100%;
  text-align: center;
  order: 2;
}

#dropdown-bottom {
  padding-bottom: 10px;
}

/* main section */

.main {
padding: 15px 20px 80px;
}

.main h1 {
margin-bottom: 10px;
color: #7CB9E8;
font-family: 'Poppins-Bold';
text-align: left;
font-size: 24px;
}

.main img {
  border-radius: 28px;
  height: 100%;
  width: 100%;
}

.main h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'InriaSans-Regular';
  font-size: 16px;
}

.main span a {
  color: #7CB9E8;
}

.main button {
display: block;
margin: 0 auto;
background-color: #7CB9E8;
color: white;
border: none;
font-size: 20px;
padding: 10px;
border-radius: 10px;
width: 8rem;
font-family: 'InriaSans-Bold';
cursor: pointer;
}

/* info section */


.info-header {
position: relative;

}

.info-header img {
height: 80px;
width: 100%;
object-fit: cover;
}

.info-header p {
  color: white;
  font-family: 'Poppins-SemiBold';
  font-weight: bold;
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cards-container {
  display: flex;
  padding: 55px 20px;
  flex-direction: column;

}

.cards img {
  margin-bottom: 20px;
  height: 55px;
  width: 100%;
}

.cards {
display: flex;
flex-direction: column;
text-align: center;
padding-bottom: 50px;
}

.card-title {
font-family: 'Poppins-Bold';
font-size: 30px;
}

.card-text {
font-family: 'Poppins-Regular';
font-size: 16px;
padding-top: 10px;
}


/* offers */

.offers-section {
  padding: 51px 20px 50px;
  border-top: 2px solid whitesmoke;
}

.offers {
  padding: 5px 20px;
}

.offers-header {
  font-family: 'Poppins-SemiBold';
  text-align: center;
  font-size: 25px;
  padding-bottom: 40px;
}

.offers-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.offers-column1 {
  border-bottom: 2px solid whitesmoke;
}

.offers-column2 {
  padding-top: 30px;
}

.offers-info {
  display: flex;
  padding-bottom: 40px;
  width: 270px;
}

.offers {
  display: flex;
  flex-direction: column;
}



.offers-column1 img, .offers-column2 img {
  height: 40px;
  width: 40px;
}


.offers-title {
  font-family: 'Poppins-SemiBold';
  font-size: 20px;
  }
  
.offers-text {
  font-family: 'Poppins-Regular';
  font-size: 13px;
  padding-top: 10px;
  color: #808080;
  }

  .offers-main-img {
      display: none;
  }


  /* options */

  .pricing {
      border-bottom: 3px solid whitesmoke;
      padding-bottom: 40px;
  }

  .pricing-header {
      font-family: 'Poppins-SemiBold';
      text-align: center;
      font-size: 27px;
      padding: 5px 20px;
  }
  .pricing-header span {
      color: #7CB9E8;
  }

  .pricing-description {
      font-family: 'Poppins-Regular';
      font-size: 12px;
      padding: 5px 20px;
      text-align: center;
      width: 220px;
      margin: 0 auto;
  }
  
  .options-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
  }

  .Options {
      border: 3px solid black;
      margin-bottom: 20px;
      width: 230px;
      transition: transform 0.3s ease-in-out;

  }



  .level {
      background-color: black;
      color: white;
      font-family: 'Poppins-Bold';
      padding: 15px;
      text-align: center;
      font-size: 23px;
  }



   .price {
      font-family: 'Poppins-Bold';
      text-align: center;
      font-size: 36px;
      padding: 20px 0px 0px;
      
   }

   .price-text {
      color: #808080;
      text-align: center;
      font-size: 18px;
      padding: 5px 20px;
   }

   .black-space {
      height: 25px;
      background-color: black;
      margin-top: 50px;
   }

   /* footer */

 

   .footer-main {
      padding: 45px 20px;
      display: flex;
      flex-direction: column;
   }

   .footer img {
      width: 240px;
     height: 100%;
   }

   .footer-logo p {
      color: #808080;
      font-family: 'Poppins-SemiBold';
      font-size: 12px;
      padding-bottom: 35px;
      padding-top: 2px;
   }

   .footer-logo {
      display: flex;
      flex-direction: column;
      
   }

   .footer-item {
      display: flex;
      flex-direction: column;
      
      padding-bottom: 20px;
   }

   .footer-text {
      font-family: 'Poppins-SemiBold';
      color: #808080;
      font-size: 12px;
   }

   .footer-title {
      font-family: 'Poppins-Bold';
      font-size: 18px;
      padding-bottom: 2px;
   }

   .footer-bottom {
      background-color: black;
      
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      text-align: center;
      padding: 15px;
      }
      
      .footer-bottom p {
          width: 60%;
          font-size: 12px;
          font-family: 'Poppins-Medium';
      }


      .workScreen-header {
          background-color: black;
          color: white ;
          padding: 20px 20px;
          text-align: center;
          font-family: 'Poppins-SemiBold';
      }

      .workScreen-header h1 {
          font-size: 25px;
      }

      .workScreen-header h2 {
          font-size: 12px;
          font-family: 'Poppins-Regular';
      }

      .workScreen-main {
          padding: 25px 20px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          
          border-bottom: 3px solid whitesmoke;
      }

      
      .workScreen-main img {
          border-radius: 20px;
          cursor: pointer;
      }

      .work-title {
          font-family: 'Poppins-Bold';
          font-size: 25px;
          padding: 5px 0px 0px;
      }

      .work-text {
          font-family: 'Poppins-Regular';
          font-size: 14px;
          color: #808080;
      }

  /*services!! */


      .ServicesScreen h1 {
          font-family: 'Poppins-SemiBold';
          text-align: center;
          padding-top: 10px;
          padding-bottom: 40px;
          font-size: 32px;
          
      }


      .services-title {
          font-family: 'Poppins-Medium';
          font-size: 24px;
      }

      .services-text {
          padding-top: 10px;
          font-family: 'Poppins-Regular';
      }


      .Services-main {
          margin: 0 auto;
          margin-bottom: 30px;
         text-align: left;
         padding: 15px 30px;
         box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          width: 200px;
          letter-spacing: .5px;
          transition: all 0.3s ease-in-out;
      }

      .Services-main:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }

      /* contact */

      .contact-container {
         padding-bottom: 20pxs;
          margin-bottom: 50px;
      
      }
     
      
      .contactScreen-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 20px 30px;
      
      }
      
      
      .contactScreen-info li {
        cursor: pointer;
          }
      
      .contactScreen-info h1 {
          font-family: 'Poppins-Medium';
      }
      
      .contactScreen-info p {
          font-family: 'Poppins-Regular';
          width: 250px;
          text-align: center;
      }

      .contactScreen-info h2 {
          font-family: 'Poppins-regular';
          padding-top: 20px;
      }
      

        .contactScreen-btns {
          width: 100%;
          margin-top: 20px;
          max-width: 280px;
          padding: 5px 20px;
          display: block;
          margin: 0 auto;
        }
      
      .input {
      border: none;
      background-color: whitesmoke;
      margin-bottom: 10px;
      padding: 10px;
      font-family: 'Poppins-Light';
      
      }
      
      .input2 {
          border: none;
          background-color: whitesmoke;
          padding: 10px;
          margin-bottom: 20px;
          height: 100px;
          resize: none;
          font-family: 'Poppins-Light';
      
      }
      
      .contact-submit {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: black;
      font-family: 'Poppins-Medium';
      padding: 10px;
      border: none;
      cursor: pointer;
     
      width: 100%;
      }
      
      
      
      .form {
          display: flex;
          flex-direction: column;
      }
      

/* about */

.aboutScreen-main  {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid whitesmoke;
}

.aboutScreen-main img {
  padding-top: 50px;
}

.about-title {
font-family: 'Poppins-Bold';
font-size: 32px;
padding-top: 20px;
padding-bottom: 10px;
text-align: center;
}

.about-text {
  font-family: 'Poppins-Medium';
  padding-bottom: 20px;
  max-width: 550px;
}

    






/* general styling media query */

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
      scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
  }
}

/* mobile - tablet */

@media (min-width:320px) {
  .logo-container, .logo-container img{    
      width: 200px;
  }
}

@media (min-width: 370px) {
  .Options {
    width: 260px;
  }
}

@media (min-width: 400px) {
  .card-text {
   width: 300px;
   margin: 0 auto;
  }

  .pricing-description {
      width: 290px; 
  }
}

@media (min-width: 390px) {
.Services-main {
  width: 260px;
}
}




@media (min-width: 468px) {
  .menu-main {
      justify-content: center;
  }
  .logo-container, .logo {
      flex: 1;
  }


}

@media (min-width: 508px) {
  .main h1 {
      font-size: 34px;
  }
.main h2 {
  text-align: center;

}
.offers-info-container {
  align-items: flex-start;
   }

  .Options {
      width: 350px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

  .pricing-description {
      width: 390px;
      margin: 0 auto;
      font-size: 14px;

  }

  .pricing-header {
      padding: 0px;
      font-size: 30px;
  }

  .level {
      font-size: 30px;
  }

  .price {
      padding: 10px 0px 0px;
      font-size: 42px;
  }

  .price-text {
      font-size: 22px;
  }

.workScreen-main {
  width: 400px;
  margin: 0 auto;
  border-bottom: 0px;
  padding: 25px 20px 60px;
}

.work-text {
  width: 300px;
  padding-bottom: 10px;
  
}

.Services-main {
  width: 350px;
}

.services-container {
  padding-bottom: 80px;

}






}

@media (min-width: 630px) {

.offers {
  margin-bottom: 20px;
}
  
.offers-info-container {
  flex-direction: row;
}

.offers-info {
margin-left: 30px;
width: 100%;
}

.offers-header {
  font-size: 32px;
}

.offers-text {
  width: 160px;
  height: 50px;
}
.offers-column1 {
  border: none;
}

.offers-column2 {
  padding-top: 0px;
}

.contactScreen-btns {
  max-width: 320px;
}
}

@media (min-width: 703px) {
  .main h1 {
      font-size: 38px;
  }

  .Services-main {
      width: 400px;
    }
}


/* Desktop */

@media (min-width: 768px) {

  .Options:hover {
    transform: translateY(-10px);
  }
  
  .header {
      border-bottom: 3px solid whitesmoke;
  }

  .logo-container, .logo-container img{
      width: 300px;
  }
  
         
  .item {
      display: block;
      width: auto;
  }
  .bars {
      display: none;
  }
  .logo {
      order: 0;
  }
  .item {
      order: 1;
  }
  .menu-main li {
      padding: 20px 7px 5px 7px;
  }

  #dropdown-bottom {
     padding-bottom: 5px;
  }


  .menu-main li a {
  font-family: 'Poppins-Regular';
}



 
 .main-info {
  display: flex;
  justify-content: center;
 
 }

 .main-info img {
  width: 490px;
 }

 .main h2 {
  width: 160px;
  margin-left: 25px;
  text-align: left;
  font-size: 18px;
 }

 .main button {
  margin-top: 50px;
  padding: 15px;
  width: 10rem;
 }

 .main h1 {
 width: 450px;
 padding-top: 20px;
 padding-bottom: 10px;
 font-size: 44px;
 }

 .main {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.info-header img {
  height: 120px;
}

.info-header p {
  font-size: 45px;
}


.cards-container {
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding-top: 50px;
}

.card-text {
width: 200px;
font-size: 14px;
text-align: inherit;
padding-top: 0px;
}

.card-title {
  font-size: 34px;
  }


.cards img {
  height: 70px;
}

.offers-info-container {
  align-items: center;
}

.offers-main-img {
  display: block;
  width: 200px;
 margin-left: 20px;
}

.offers-info {
  margin-left: 0px;
}

.offers-title {
  font-size: 16px;
}


.options-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 35px;
}

.Options {
  width: 220px;
}

.price-text {
  font-size: 18px;
}



.footer-main {
  flex-direction: row;
  align-items: baseline;
  gap: 50px;
  justify-content: center;
  
}

.footer-container {
  display: flex;
  gap: 50px;
}

.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Services-main {
  width: 280px;
}

.contact-container {
  display: flex;
  width: 700px;
  height: 600px;
 justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
  
}



}




@media (min-width: 930px) {
  .card-text {
      font-size: 16px;
  }

  .cards-container {
      gap: 80px;
  }


.offers-info-container {
  align-items: flex-start;
}

.offers-main-img {
  width: 250px;
 
}


.offers-info {
  margin-left: 20px;
}


.options-container {
  gap: 50px;
}

.Options {
  width: 240px;
}

}

@media (min-width: 1000px) {
  .services-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .Services-main {
      width: 250px;
    }

  

  
  .contactScreen-btns {
      max-width: 400px;
  }

  .contact-container {
      gap: 60px;
  }
}


@media (min-width: 1100px) {
  .main-info img {
      width: 600px;
  }
  .main h2 {
      font-size: 20px;
      width: 160px;
  }


.offers-main-img {
  width: 270px;
  margin-left: 50px;
}

.offers-info {
  margin-left: 30px;
}


}



@media (min-width: 1300px) {
  .menu-main li a {
      font-size: 16px;
  }

  .main h1 {
      font-size: 50px;
      width: 600px;
  }

  .main h2 {
      width: 170px;
      height: 170px;
  }


  .main-info img {
      width: 700px;
  }

  .info-header p {
      font-size: 55px;
  }

  .info-header img {
      height: 130px;
  }

  .card-text {
      font-size: 18px;
      width: 250px;
     
  }

  .card-title {
      font-size: 40px;
  }

  .cards-container {
      gap: 100px;
     
  }

  .offers-main-img {
      width: 270px;
      margin-left: 50px;
  }
  
  .offers-info {
      margin-left: 50px;
  }

  .offers-title {
      font-size: 22px ;
  }

  .offers-text {
      font-size: 15px;
      width: 250px;
  }

  .offers-header {
      font-size: 44px;
  }

  .offers-header {
      padding-bottom: 70px;
  }

  .options-container {
      gap: 50px;
  }
  
  .Options {
      width: 300px;
      
  }

  .pricing-header {
      font-size: 40px;
  }

.footer-title {
font-size: 22px;
}

.footer-text {
font-size: 14px;
}

.footer img {
width: 260px;
}

.footer-logo p {
font-size: 14px;
}

.footer-bottom {
  padding: 25px;
}

.workScreen-header {
  padding: 30px 20px;
}

.workScreen-header h1 {
  font-size: 32px;
}

.workScreen-header h2 {
  font-size: 16px;
}

.workScreen-main {

  padding: 80px 20px 60px;



}

.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Services-main {
  width: 240px;
}

.contact-container {
  height: 500px;
}

.about-container {
  display: flex;
  gap: 40px;
}

.about-text {
  max-width: 500px;
  font-family: 'Poppins-Regular';
}

}

@media (min-width: 1800px) {

    .Services-main {
      width: 320px;
    }

    .contact-container {
      height: 550px;
  }
}
